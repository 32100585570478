export const NavLocation = {
	ABOUT_ME: 'ABOUT_ME',
	RATES: 'RATES',
	CONTACT: 'CONTACT',
}

export const NavBarNames = {
	ABOUT_ME: 'Over mij',
	RATES: 'Tarieven',
	CONTACT: 'Contact',
}

export const NavAnchors = {
	ABOUT_ME: 'over-mij',
	RATES: 'tarieven',
	CONTACT: 'contact',
}

export const FormState = {
	DEFAULT: 'DEFAULT',
	WAITING_FOR_CAPTCHA: 'WAITING_FOR_CAPTCHA',
	WAITING_FOR_MAILJS: 'WAITING_FOR_MAILJS',
	MAIL_SENT: 'MAIL_SENT',
}
