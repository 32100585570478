import * as React from 'react'
import NavBar from './NavBar'
import { navigate } from 'gatsby'
import { NavBarNames, NavLocation, NavAnchors } from '../constants'

const FooterLocation = function ({ type }) {
	const click = function () {
		navigate('/#' + NavAnchors[type])
	}

	return (
		<li>
			<button className={'menu-button'} onClick={click}>
				{NavBarNames[type] || 'Naar boven'}
			</button>
		</li>
	)
}

const Footer = ({ outsideStore }) => {
	return (
		<div className={'block block-footer'}>
			<div className={'block-content'}>
				<div>
					<h1>Kunyit</h1>
					<h3>Karin Seydel</h3>
					<h3>Rouwpsycholoog</h3>
					<p>KvK-nummer 83350233</p>
				</div>
				<div>
					<h4>Contactinformatie</h4>
					<ul>
						<li>
							<a title={'Bel mij'} href={'tel:31621588511'}>
								06 21 58 85 11
							</a>
						</li>
						<li>
							<a
								title={'Stuur mij een email'}
								href={'mailto:karin.seydel@kunyit.nl'}
							>
								karin.seydel@kunyit.nl
							</a>
						</li>
						<li aria-label={'Mijn adres'}>Bas Backerlaan 5</li>
						<li>7316 DX Apeldoorn</li>
					</ul>
				</div>
				<div>
					<h4>Menu</h4>
					{outsideStore ? (
						<ul role={'navigation'} className={'menu'}>
							{Object.keys(NavLocation).map((key) => (
								<FooterLocation key={key} type={key}></FooterLocation>
							))}
						</ul>
					) : (
						<NavBar />
					)}
				</div>
			</div>
		</div>
	)
}

export default Footer
