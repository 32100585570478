export const GO_TO = 'GO_TO'
export const SET_NAVBAR_DOCKED = 'SET_NAVBAR_DOCKED'

export const goTo = (location) => ({
	type: GO_TO,
	payload: {
		location,
	},
})

export const setNavbarDocked = (isDocked) => ({
	type: SET_NAVBAR_DOCKED,
	payload: {
		isDocked,
	},
})
