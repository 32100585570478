import * as React from 'react'
import Cookies from 'js-cookie'
import { CSSTransition } from 'react-transition-group'

const COOKIE_NAME = 'kunyit-gdpr-google-analytics'

const CustomCookieConsent = () => {
	const [isVisible, setVisible] = React.useState(false)

	React.useEffect(() => {
		const cookieValue = Cookies.get(COOKIE_NAME)

		if (typeof cookieValue === 'undefined') {
			setVisible(true)
		}
	}, [setVisible])

	const click = React.useCallback(() => {
		Cookies.set(COOKIE_NAME, true, {
			expires: 365,
			sameSite: 'lax',
			cookieSecurity: 'https',
		})
		setVisible(false)
	}, [setVisible])

	return (
		<CSSTransition
			in={isVisible}
			timeout={100}
			unmountOnExit
			classNames='animation'
		>
			<div className={'cookie-consent'}>
				<div className={'content'}>
					<div className={'text-container'}>
						Ik gebruik analytische cookies om bij te houden hoe vaak de website
						wordt bezocht.
					</div>
					<div className={'button-container'}>
						<button
							onClick={click}
							aria-label={
								'Accepteer de analytische cookies van Karinseydel.com'
							}
						>
							Accepteer cookies
						</button>
					</div>
				</div>
			</div>
		</CSSTransition>
	)
}

export default CustomCookieConsent
