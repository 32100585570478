import * as React from 'react'
import { NavLocation } from '../constants'
import NavBarLocation from './NavBarLocation'

const NavBar = function ({ onClick }) {
	return (
		<ul role={'navigation'} className={'menu'}>
			{Object.keys(NavLocation).map((key) => (
				<NavBarLocation onClick={onClick} type={key} key={key} />
			))}
		</ul>
	)
}

export default NavBar
