import * as React from 'react'
import { connect } from 'react-redux'
import { goTo } from '../redux/actions/navActions'
import { useLocation } from '@reach/router'
import { navigate } from 'gatsby'
import { NavBarNames } from '../constants'

const DisconnectedNavBarLocation = function ({ onClick, type, goTo }) {
	const location = useLocation()
	const click = function () {
		if (location.pathname !== '/') navigate('/')
		if (typeof onClick === 'function') onClick()
		goTo(type)
	}
	const button = (
		<button className={'menu-button'} onClick={click}>
			{NavBarNames[type] || 'Naar boven'}
		</button>
	)

	if (type === null) {
		return button
	} else return <li>{button}</li>
}

const NavBarLocation = connect(null, { goTo })(DisconnectedNavBarLocation)

export default NavBarLocation
